import { VideoConfigModule } from "@zipou/video_front"

type VideoConfigModuleDisplayProps = {
  tokenId: string,
  token: string,
}

export const VideoConfigModuleDisplay = ({ tokenId, token, }: VideoConfigModuleDisplayProps) => {

  const [url, wsUrl] = process.env.NODE_ENV === "development" ? ["http://localhost:4001", "ws://localhost:4001/subscriptions"] : ["https://www.box4b.fr", "wss://www.box4b.fr/subscriptions"]

  return <div>
    <VideoConfigModule
      tokenId={tokenId}
      token={token || ""}
      moduleConfig={{
        url,
        wsUrl,
      }}
    />
  </div>

}