import { useDrop, useDrag } from 'react-dnd'
import { useMutation, useQuery } from "@apollo/client"
import tagGetGql from "graphql/BILLING/Tag/query/tagGet.gql"
import { Tag } from "model"
import tagMoveGql from 'graphql/BILLING/Tag/mutation/tagMove.gql'
import tagListRootGql from 'graphql/BILLING/Tag/query/tagListRoot.gql'
import { useState } from 'react'
import { TagEdit } from '../Edit/TagEdit'
import tagDeleteGql from 'graphql/BILLING/Tag/mutation/tagDelete.gql'
import { IconEdit, IconTrash, Modal } from '@zipou/front_tools'

type TagDisplayProps = {
  id: string,
  onDone?: () => void,
}

export const TagDisplay = ({ id, onDone }: TagDisplayProps) => {

  const [focusUpdate, updateFocusUpdate] = useState("")


  const [mutate] = useMutation(tagMoveGql)
  const [mutateDelete] = useMutation(tagDeleteGql)

  const { data, refetch } = useQuery<{ tag: Tag }>(tagGetGql, {
    fetchPolicy: "network-only",
    variables: {
      id,
    }
  })
  const tag = data?.tag
  const children = tag?.Children || []
  const hasChildren = children?.length > 0

  // const name = tag?.id
  // const type = "tag"

  // const [{ opacity }, drag] = useDrag(
  //   () => ({
  //     type,
  //     item: { id: tag?.id },
  //     collect: (monitor) => ({
  //       opacity: monitor.isDragging() ? 0.4 : 1,
  //     }),
  //   }),
  //   [name, type],
  // )

  // const onMove = (id: string, parentId: string) => {
  //   mutate({
  //     fetchPolicy: "network-only",
  //     variables: {
  //       id,
  //       parentId,
  //     },
  //     refetchQueries: [{
  //       query: tagListRootGql
  //     },
  //     {
  //       query: tagGetGql,
  //       variables: {
  //         id: parentId
  //       }
  //     },
  //     {
  //       query: tagGetGql,
  //       variables: {
  //         id
  //       }
  //     },
  //     {
  //       query: tagGetGql,
  //       variables: {
  //         id: tag?.parentId,
  //       }
  //     }
  //     ]
  //   })
  // }

  const onDelete = () => {
    mutateDelete({
      variables: {
        id: tag?.id,
      },
      refetchQueries: [{
        query: tagListRootGql
      },
      {
        query: tagGetGql,
        variables: {
          id: tag?.parentId
        }
      },
      ]
    })

  }

  // const [{ canDrop, isOver }, drop] = useDrop(() => ({
  //   accept: type,
  //   drop: (item: any, mon) => {
  //     if (item?.id !== id) {
  //       onMove(item?.id, id)
  //     }
  //     //  console.log(`ITEM DROPPED ON ${props?.id} `, item)
  //   },
  //   collect: (monitor) => ({
  //     isOver: monitor.isOver(),
  //     canDrop: monitor.canDrop(),
  //   }),
  // }))

  return <>
    <Modal display={!!focusUpdate} title='Modifier un tag' onClose={() => updateFocusUpdate("")}>
      <TagEdit id={focusUpdate} onDone={() => {
        updateFocusUpdate("")
        onDone && onDone()
      }} />
    </Modal>
    {/* <ul ref={drag} style={{ padding: 0 }}> */}
    {/* <li ref={drop}> */}
    <ul style={{ padding: 0 }}>
      <li>
        <span className='badge badge-info' style={{ backgroundColor: tag?.color ? `#${tag.color}` : "" }}>
          {tag?.name} -
          <span className='icon-edit ml-2' onClick={() => updateFocusUpdate(id)}>
            <IconEdit />
          </span>
          <span className='icon-trash ml-2' onClick={() => onDelete()}>
            <IconTrash />
          </span>
        </span>
      </li>
      {hasChildren && <ul>
        {children?.map((t) => {
          return <TagDisplay id={t.id} key={t.id} />
        })}
      </ul>}
    </ul>
  </>
}
