import { gql } from "@apollo/client";
import CameraEditFragmentGql from "graphql/VIDEO/Camera/fragment/CameraEditFragment.gql";

export default gql`


${CameraEditFragmentGql}

fragment VideoConfigFragment on VideoConfig {
  id
  name
  token
  storageDir
  mouvementDetectionEnableResolutionConversion
  mouvementDetectionNbFrameToSkip
  CameraList { 
    ...CameraEditFragment
  }
  VideoToken {
    id
    name
    token
  }
}

`