import { PanelDisplayModule } from "components/backoffice/VIDEO/Panel/Module/PanelDisplayModule"
import { useParams } from "react-router-dom"

export const PanelDisplayRoute = () => {

  const params = useParams()
  const { tokenId, token, panelId } = params
  const hasParams = !!tokenId && !!panelId && !!token

  return <div>
    {hasParams && <PanelDisplayModule tokenId={tokenId} token={token} panelId={panelId} />}
  </div>

}