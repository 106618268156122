import { useMutation } from "@apollo/client";
import clusterDelete from "graphql/HOSTING/Cluster/Cluster/mutation/clusterDelete";
import { useNavigate } from "react-router-dom";
import { URL_HOSTING_CLUSTER_ADD, URL_HOSTING_CLUSTER_EDIT } from "static/constant/backoffice/url";

import { useQuery } from "@apollo/client"
import clusterListGql from "graphql/HOSTING/Cluster/Cluster/query/clusterList"
import { IconEdit, IconTrash } from "@zipou/front_tools";
import { Cluster } from "model";

type ClusterListProps = {

}

export const ClusterList = (props: ClusterListProps) => {

  const { data } = useQuery<{ list: Cluster[] }>(clusterListGql)
  const navigate = useNavigate()
  const [mutate] = useMutation(clusterDelete)

  const onDelete = (id: string) => {
    mutate({
      variables: {
        id,
      },
      refetchQueries: [{
        query: clusterListGql
      }]
    })
  }

  const clusterList = data?.list

  const clusterListSorted = [...(clusterList || [])]?.sort((el1, el2) => el1?.name?.localeCompare(el2?.name) > 0 ? -1 : 1) || []

  const hasMulti = true

  return <div className="cluster-list-container">
    <table className="table table-bordered table-striped">
      <thead>
        <tr className="bg-dark text-white">
          {hasMulti && <th className="bg-dark text-white">Config</th>}
          <th className="bg-dark text-white">Nom</th>
          <th className="bg-dark text-white">Serveurs</th>
          <th className="bg-dark text-white">Actions</th>
        </tr>
      </thead>
      <tbody>
        {clusterListSorted?.map(cluster => {
          return <tr>
            {hasMulti && <td>{cluster?.HostingConfig?.name}</td>}
            <td>{cluster?.name}</td>
            <td>
              <table className="table table-striped table-bordered">
                {cluster?.ClusterConfig?.map((cc) => {
                  return <tr>
                    <td>
                      <span className="badge badge-sm badge-dark">{cc.Server?.name}</span>
                    </td>
                  </tr>
                })}
              </table>
            </td>
            <td>
              <button className="btn btn-sm btn-dark ml-2" onClick={() => navigate("/manager" + URL_HOSTING_CLUSTER_EDIT.replace(':id', cluster.id))}>
                <IconEdit />
              </button>
              <button className="btn btn-sm btn-danger ml-2" onClick={() => onDelete(cluster.id)}>
                <IconTrash />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table>
    <div className="">
      <button className="btn btn-sm btn-dark" onClick={() => navigate("/manager" + URL_HOSTING_CLUSTER_ADD)}>Ajouter</button>
    </div>
  </div>


}

