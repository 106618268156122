import { NumberInput, Price, TextInput } from "@zipou/front_tools"
import { ItemInput } from "model"
import { TagSelectInput } from "../../Transaction/TagSelectInput"

type ItemFormProps = {
  item: ItemInput,
  onChange: (item: ItemInput) => void,
  errors: any,
}

export const ItemForm = ({ item, errors, onChange }: ItemFormProps) => {


  return <div>
    <TextInput label="Label" value={item?.label || ""} id="label" errors={errors} onChange={(v) => {
      onChange({
        ...item,
        label: v
      })
    }} />
    <Price label="Prix" value={item?.price} id="price" errors={errors} onChange={(v) => {
      onChange({
        ...item,
        price: Number(v),
      })
    }} />
    <NumberInput label="TVA" value={`${item?.vat || ""}`} id="tva" errors={errors} onChange={(_, v) => {
      onChange({
        ...item,
        vat: v,
      })
    }} />

    <Price label="Prix Achat" value={item?.priceBuy} id="priceBuy" errors={errors} onChange={(v) => {
      onChange({
        ...item,
        priceBuy: Number(v),
      })
    }} />

    <TagSelectInput tagId={item?.tagId || ""} onChange={(tagId) => {
      onChange({
        ...item,
        tagId,
      })
    }} />


  </div>
}