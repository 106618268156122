import {gql} from "@apollo/client"
import ItemFragment from "../../Item/fragment/ItemFragment"

export default gql`

  ${ItemFragment}

  fragment InvoiceLineFragment on InvoiceLine {
    id
    quantity
    label
    dateStart
    dateEnd
    vat
    priceUnit
    tagId
    Item {
      ...ItemFragment
    }
  }
`