import { useState } from "react"

import "./style.scss"

import notificationListQuery from 'graphql/Notification/query/notificationList'
import invoiceGetQuery from "graphql/BILLING/Invoice/query/invoiceOwnerGet"
import contractListQuery from "graphql/BILLING/Contract/query/contractList"
import invoiceUpdateMutation from "graphql/BILLING/Invoice/mutation/invoiceUpdate"
import { useMutation, useQuery } from "@apollo/client";
import { formatErrorResponseForJoi } from "helpers/Apollo";
import { Invoice, InvoiceInput } from "model";
import { InvoiceForm } from "../Form/InvoiceForm";

type InvoiceEditProps = {
  invoiceId?: string,
  onDone: () => void,
}

export const InvoiceEdit = ({ onDone, invoiceId }: InvoiceEditProps) => {

  const contractListReponse = useQuery(contractListQuery)
  const contractList = contractListReponse?.data?.contractList || []
  const contractListSorted = [...contractList]?.sort((el1: any, el2: any) => el1?.Customer?.name.localeCompare(el2?.Customer?.name)) || []

  const [isUpdated, updateIsUpdated] = useState<boolean>(false)
  const [invoice, updateInvoice] = useState<InvoiceInput | null>(null)
  const [errors, updateErrors] = useState<any>(null)

  const [invoiceUpdate] = useMutation(invoiceUpdateMutation)


  const getInput = (invoice: Invoice): InvoiceInput => {
    return {
      status: invoice?.status,
      contractId: invoice?.contractId,
      contactId: invoice?.contactId,
      date: invoice?.date,
      InvoiceLine: invoice?.InvoiceLine?.map((invoiceLine) => ({
        id: invoiceLine?.id,
        quantity: invoiceLine?.quantity,
        vat: invoiceLine?.vat,
        priceUnit: invoiceLine?.priceUnit,
        label: invoiceLine?.label,
        dateStart: invoiceLine?.dateStart,
        dateEnd: invoiceLine?.dateEnd,
        priceBuy: invoiceLine?.priceBuy,
        itemId: invoiceLine?.itemId,
        tagId: invoiceLine?.tagId,
      })),
      Payment: invoice?.Payment?.map((payment) => ({
        id: payment?.id,
        date: payment?.date,
        type: payment?.type,
        value: payment?.value,
        comment: payment?.comment,
      }))
    }
  }


  useQuery<{ invoice: Invoice }>(invoiceGetQuery, {
    skip: !invoiceId,
    variables: {
      id: invoiceId
    },
    onCompleted: (response) => {
      const invoice = response.invoice
      updateInvoice(getInput(invoice))
    }
  })

  const onChange = (invoice: InvoiceInput) => {
    updateIsUpdated(true)
    updateInvoice(invoice)
  }


  const onSave = () => {

    invoiceUpdate({
      variables: {
        id: invoiceId,
        input: invoice,
      },
      refetchQueries: [
        {
          query: notificationListQuery,
          variables: {
            cursor: {
              page: 1,
              limit: 30,
            }
          },
        }
      ]
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  return <div className="invoice-edit-container">
    {invoice && <InvoiceForm invoice={invoice} onChange={onChange} errors={errors} />}
    {isUpdated && <div className="actions bg-white p-2" style={{ position: "sticky", bottom: 10, width: "100%", border: "2px solid #ced4da" }}>
      <button className="btn btn-sm btn-dark" onClick={() => onSave()}>Enregistrer</button>
    </div>}

  </div >
}

