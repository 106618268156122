import { PanelListModule } from "@zipou/video_front"
import { useNavigate } from "react-router-dom"
// import { useHistory } from "react-router-dom"
import { URL_PANEL_DISPLAY } from "static/constant/backoffice/url"

type PanelListModuleDisplayProps = {
  tokenId: string,
  token: string,
}

export const PanelListModuleDisplay = ({ tokenId, token }: PanelListModuleDisplayProps) => {

  const navigate = useNavigate()
  const env = process.env.NODE_ENV

  const moduleConfig = env === "development" ? {
    url: "http://localhost:4001",
    wsUrl: "ws://localhost:4001",
  } : {
    url: "https://www.box4b.fr",
    wsUrl: "wss://www.box4b.fr/subscriptions",
  }


  return <div>
    <PanelListModule
      tokenId={tokenId}
      token={token}
      moduleConfig={moduleConfig}
      onPanelClick={(panel) => navigate("" + URL_PANEL_DISPLAY.replace(':tokenId', tokenId).replace(":token", token).replace(":panelId", panel?.id))}
    />
  </div>

}