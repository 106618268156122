import { gql } from "@apollo/client";
import OrderLineListFragmentGql from "graphql/BILLING/Order/Line/fragment/OrderLineListFragment.gql";
import TagFragment from "graphql/BILLING/Tag/fragment/TagFragment";

export default gql`

  ${OrderLineListFragmentGql}
  ${TagFragment}

  fragment ItemFragment on Item {
    id
    label
    price
    vat
    priceBuy
    tagId
    Tag {
      ...TagFragment
    }
    OrderLine {
      ...OrderLineListFragment
    }
  }
`