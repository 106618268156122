import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useMutation, useQuery } from '@apollo/client'

import { TextInput, AutoCompleteInput, IconTrash, IconEdit, Modal, IconPlay, NumberInput } from '@zipou/front_tools'

import serverListGql from 'graphql/HOSTING/Server/query/serverList'
import clusterRoleListQuery from "graphql/HOSTING/Cluster/ClusterRole/query/clusterRoleList"

import { LoadBalancerForm } from '../Role/LoadBalancer/LoadBalancerForm'
import { IpsecForm } from '../Role/Ipsec/IpsecForm'
import { DnsForm } from '../Role/Dns/DnsForm'
import { PrometheusForm } from '../Role/Prometheus/PrometheusFrom'
import { BlackboxExporterForm } from '../Role/BlackboxExporter/BlackboxExporterForm'
import { AlertManagerForm } from '../Role/AlertManager/AlertManagerForm'
import { SnmpExporterForm } from '../Role/SnmpExporter/SnmpExporter'
import { AsteriskForm } from '../Role/Asterisk/AsteriskForm'
import { ClusterRoleConfig, ClusterRole, ClusterRoleTypeEnum, Server, ClusterInput, ClusterConfigInput, ClusterRoleConfigStatusEnum } from 'model'
import clusterRoleConfigForceDeployGql from 'graphql/HOSTING/Cluster/ClusterRole/Config/mutation/clusterRoleConfigForceDeploy.gql'
import { ClusterRoleBadge } from '../../Role/Badge/ClusterRoleBadge'

type ClusterConfigFormProps = {
  clusterConfig: ClusterConfigInput,
  onChange: (clusterConfig: ClusterConfigInput) => void,
  cluster?: ClusterInput,
  // serverId: Server,
}

export const ClusterConfigForm = ({ clusterConfig, cluster, onChange }: ClusterConfigFormProps) => {

  const [value, updateValue] = useState<ClusterRoleConfig>();
  const [roleFocus, updateRoleFocus] = useState<any>();

  const clusterRoleListData = useQuery(clusterRoleListQuery)
  const clusterRoleList: ClusterRole[] = clusterRoleListData?.data?.clusterRoleList

  const serverListData = useQuery<{ serverList: Server[] }>(serverListGql)
  const serverList = serverListData?.data?.serverList

  const onSearch = async (textValue: string) => {
    return clusterRoleList?.filter(clusterRole => {
      return clusterRole?.name?.toLocaleLowerCase().includes(textValue?.toLocaleLowerCase())
    })
  }

  const onAutoCompleteSelect = (clusterRole?: ClusterRoleConfig) => {
    const { id, name, type, version } = clusterRole || {}
    onChange({
      ...clusterConfig,
      ClusterRoleConfig: [
        ...(clusterConfig?.ClusterRoleConfig || []),
        { id: `${uuid()}`, clusterRoleId: id, name, version: `${version}`, status: ClusterRoleConfigStatusEnum.INITIAL },
      ]
    })
  }

  const renderRoleSubForm = (rolePayload: ClusterRoleConfig) => {
    const roleMatch = clusterRoleList?.find((el) => el?.id === rolePayload?.clusterRoleId)
    const options = rolePayload?.options
    switch (roleMatch?.type) {

      case ClusterRoleTypeEnum.LOADBALANCER: {
        return <Modal size='xl' title='Loadbalancer' display={!!roleFocus} onClose={() => updateRoleFocus(null)}>
          <div>
            <LoadBalancerForm
              options={options}
              cluster={cluster as ClusterInput}
              clusterConfig={clusterConfig}
              onChange={(opts) => {
                onChange({
                  ...clusterConfig,
                  ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                    ...el,
                    options: opts,
                  } : el)
                })
              }} />
          </div>
        </Modal>

      }
      case ClusterRoleTypeEnum.DNS: {
        return <Modal size='xl' title='DNS' display={!!roleFocus} onClose={() => updateRoleFocus(null)}>
          <div className="input-group">
            <span className="input-group-text">DNS</span>
            <div className="form-control" style={{ height: "100%" }}>
              <DnsForm options={options} onChange={(opts) => {
                onChange({
                  ...clusterConfig,
                  ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                    ...el,
                    options: opts,
                  } : el)
                })
              }} />
            </div>
          </div>
        </Modal>

      }

      case ClusterRoleTypeEnum.PROMETHEUS: {
        return <Modal size='xl' title='Prometheus' display={!!roleFocus} onClose={() => updateRoleFocus(null)}>
          {/* <div className="input-group">
            <span className="input-group-text">Prometheus</span>
            <div className="form-control" style={{ height: "100%" }}> */}
          <PrometheusForm options={options} onChange={(opts: any) => {
            onChange({
              ...clusterConfig,
              ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                ...el,
                options: opts,
              } : el)
            })
          }} />
          {/* </div>
          </div> */}
        </Modal>

      }
      case ClusterRoleTypeEnum.BLACKBOX_EXPORTER: {
        return <Modal size='xl' title='Blackbox' display={!!roleFocus} onClose={() => updateRoleFocus(null)}>
          <div className="input-group">
            <span className="input-group-text">BLACKBOX EXPORTER</span>
            <div className="form-control" style={{ height: "100%" }}>
              <BlackboxExporterForm config={options} onChange={(opts: any) => {
                onChange({
                  ...clusterConfig,
                  ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                    ...el,
                    options: opts,
                  } : el)
                })
              }} />
            </div>
          </div>
        </Modal>

      }
      case ClusterRoleTypeEnum.SNMP_EXPORTER: {
        return <Modal size='xl' title='SNMP Exporter' display={!!roleFocus} onClose={() => updateRoleFocus(null)}>
          <div className="input-group">
            <span className="input-group-text">SNMP EXPORTER</span>
            <div className="form-control" style={{ height: "100%" }}>
              <SnmpExporterForm config={options} onChange={(opts: any) => {
                onChange({
                  ...clusterConfig,
                  ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                    ...el,
                    options: opts,
                  } : el)
                })
              }} />
            </div>
          </div>
        </Modal>

      }
      case ClusterRoleTypeEnum.ASTERISK: {
        return <Modal size='xl' title='ASTERISK' display={!!roleFocus} onClose={() => updateRoleFocus(null)}>
          <div className="input-group">
            <span className="input-group-text">ASTERISK</span>
            <div className="form-control" style={{ height: "100%" }}>
              <AsteriskForm errors={{}} config={options} onChange={(opts: any) => {
                onChange({
                  ...clusterConfig,
                  ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                    ...el,
                    options: opts,
                  } : el)
                })
              }} />
            </div>
          </div>
        </Modal>

      }
      case ClusterRoleTypeEnum.ALERT_MANAGER: {
        return <Modal size='xl' title='ALERT MANAGER' display={!!roleFocus} onClose={() => updateRoleFocus(null)}>
          <div className="input-group">
            <span className="input-group-text">ALERT MANAGER</span>
            <div className="form-control" style={{ height: "100%" }}>
              <AlertManagerForm config={options} onChange={(opts: any) => {
                onChange({
                  ...clusterConfig,
                  ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                    ...el,
                    options: opts,
                  } : el)
                })
              }} />
            </div>
          </div>
        </Modal>

      }
      case ClusterRoleTypeEnum.IPSEC: {
        return <Modal size='xl' title='IPSEC' display={!!roleFocus} onClose={() => updateRoleFocus(null)}>
          <TextInput id="name" value={rolePayload?.name || ""} errors={{}} label='Nom' onChange={v => {
            onChange({
              ...clusterConfig,
              ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                ...el,
                name: v,
              } : el)
            })
          }} />
          <div className="input-group">
            <span className="input-group-text">IPSEC</span>
            <div className="form-control" style={{ height: "100%" }}>
              <IpsecForm options={options} onChange={(opts: any) => {
                onChange({
                  ...clusterConfig,
                  ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                    ...el,
                    version: `${Number(el?.version) + 1}`,
                    options: opts,
                  } : el)
                })
              }} />
            </div>
          </div>
        </Modal>

      }

      case ClusterRoleTypeEnum.PHP: {

        return <Modal size='xl' title='PHP' display={!!roleFocus} onClose={() => updateRoleFocus(null)}>
          <TextInput id="name" value={rolePayload?.name || ""} errors={{}} label='Nom' onChange={v => {
            onChange({
              ...clusterConfig,
              ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                ...el,
                name: v,
              } : el)
            })
          }} />
          <div className="input-group">
            <span className="input-group-text">Port</span>
            <div className="form-control" style={{ height: "100%" }}>
              <NumberInput value={`${rolePayload?.port}` || ""} label='Port' errors={{}} id="port" onChange={(_, v) => {
                onChange({
                  ...clusterConfig,
                  ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                    ...el,
                    port: v,
                  } : el)
                })
              }} />
            </div>
          </div>
        </Modal>
      }

      case ClusterRoleTypeEnum.NODE: {

        return <Modal size='xl' title='NODE' display={!!roleFocus} onClose={() => updateRoleFocus(null)}>
          <TextInput id="name" value={rolePayload?.name || ""} errors={{}} label='Nom' onChange={v => {
            onChange({
              ...clusterConfig,
              ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                ...el,
                name: v,
              } : el)
            })
          }} />
          <div className="input-group">
            <span className="input-group-text">Port</span>
            <div className="form-control" style={{ height: "100%" }}>
              <NumberInput value={`${rolePayload?.port}` || ""} label='Port' errors={{}} id="port" onChange={(_, v) => {
                onChange({
                  ...clusterConfig,
                  ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                    ...el,
                    port: v,
                  } : el)
                })
              }} />
            </div>
          </div>
        </Modal>

      }

      default:
      case ClusterRoleTypeEnum.SYSLOG:
      case ClusterRoleTypeEnum.STATIC:
      case ClusterRoleTypeEnum.UNIFI:
      case ClusterRoleTypeEnum.MINIO:
      case ClusterRoleTypeEnum.METABASE:
      case ClusterRoleTypeEnum.GRAFANA:
      case ClusterRoleTypeEnum.FTP:
      case ClusterRoleTypeEnum.DBADMIN:
      case ClusterRoleTypeEnum.MEILISEARCH:
      case ClusterRoleTypeEnum.CACHE:
      case ClusterRoleTypeEnum.DATABASE: {

        return <Modal size='xl' title={rolePayload?.type || ""} display={!!roleFocus} onClose={() => updateRoleFocus(null)}>
          <TextInput id="name" value={rolePayload?.name || ""} errors={{}} label='Nom' onChange={v => {
            onChange({
              ...clusterConfig,
              ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                ...el,
                name: v,
              } : el)
            })
          }} />
        </Modal>
      }

      case ClusterRoleTypeEnum.MQTT_EXPORTER: {

        return <Modal size='xl' title={rolePayload?.type || ""} display={!!roleFocus} onClose={() => updateRoleFocus(null)}>
          <TextInput id="name" value={rolePayload?.name || ""} errors={{}} label='Nom' onChange={v => {
            onChange({
              ...clusterConfig,
              ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                ...el,
                name: v,
              } : el)
            })
          }} />

          <TextInput id="mqttAddress" value={rolePayload?.options?.mqttAddress || ""} errors={{}} label='Adresse MQTT' onChange={v => {
            onChange({
              ...clusterConfig,
              ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.map((el) => (el?.clusterRoleId === rolePayload?.clusterRoleId) ? {
                ...el,
                options: {
                  ...(el.options || {}),
                  mqttAddress: v,
                },
              } : el)
            })
          }} />
        </Modal>
      }
    }
  }

  return <div className="cluster-config-form-container d-flex flex-column ">
    {
      //@ts-ignore
      renderRoleSubForm(clusterConfig?.ClusterRoleConfig?.find(el => el?.id === roleFocus))
    }
    <TextInput rightContent={<span className="badge badge-warning">.{cluster?.name}</span>} label={'Nom'} id="name" value={clusterConfig?.name || ""} errors={{}} onChange={(v) => {
      onChange({
        ...clusterConfig,
        name: v,
      })
    }}
    />

    <div className='input-group'>
      <div className="input-group-text">Serveur</div>
      <select className='form-control' value={clusterConfig?.serverId || ""} onChange={(e) => {
        onChange({
          ...clusterConfig,
          serverId: e.target.value
        })
      }}>
        <option value="" disabled>Choisir</option>
        {serverList?.map(server => {
          return <option key={`server_${server?.id}`} value={server?.id}>{server?.name}</option>
        })}
      </select>

    </div>

    <div className="input-group">
      <div className="input-group-text">Roles</div>
      <div className='form-control' style={{ height: "100%" }}>
        <AutoCompleteInput<ClusterRoleConfig>
          value={value}
          onSearch={onSearch}
          label='Ajouter un role'
          onChange={onAutoCompleteSelect}
          displayValue={value => value?.name || ""}
          isError={false}
          disabled={false}
        />
        <div className="">
          <table className='table table-stripped table-bordered'>
            <thead>
              <tr className='bg-dark text-white'>
                <td className='bg-dark text-white'>Nom</td>
                <td className='bg-dark text-white'>Type</td>
                <td className='bg-dark text-white'>Actions</td>
              </tr>
            </thead>
            <tbody>
              {clusterConfig?.ClusterRoleConfig?.map(roleConfig => {

                const isEditable = true

                // const isEditable = roleConfig?.type === ClusterRoleTypeEnum.LOADBALANCER ||
                //   roleConfig?.type === ClusterRoleTypeEnum.DNS ||
                //   roleConfig?.type === ClusterRoleTypeEnum.IPSEC ||
                //   roleConfig?.type === ClusterRoleTypeEnum.ALERT_MANAGER ||
                //   roleConfig?.type === ClusterRoleTypeEnum.SNMP_EXPORTER ||
                //   roleConfig?.type === ClusterRoleTypeEnum.BLACKBOX_EXPORTER ||
                //   roleConfig?.type === ClusterRoleTypeEnum.PROMETHEUS ||
                //   roleConfig?.type === ClusterRoleTypeEnum.ASTERISK

                return <tr key={`role_config_${roleConfig?.id}`}>
                  <td>{roleConfig?.name}</td>
                  <td>
                    {roleConfig?.clusterRoleId && <ClusterRoleBadge id={roleConfig?.clusterRoleId} />}
                  </td>
                  <td>
                    {isEditable && <button className='btn btn-sm btn-warning ml-1' onClick={() => {
                      updateRoleFocus(roleConfig?.id)
                    }}>
                      <IconEdit />
                    </button>}
                    <button className='btn btn-sm btn-danger ml-1' onClick={() => {
                      onChange({
                        ...clusterConfig,
                        ClusterRoleConfig: clusterConfig?.ClusterRoleConfig?.filter(el => {
                          return el.clusterRoleId !== roleConfig?.clusterRoleId
                        }),
                      })
                    }}>
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
}