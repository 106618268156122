import { SelectInput, NumberInput, DateInput, IconWarningSign } from "@zipou/front_tools"

import contractListQuery from "graphql/BILLING/Contract/query/contractList"
import { useQuery } from "@apollo/client"
import { OrderLineForm } from "../../OrderLine/Form/OrderLineForm"
import { Contract, Order, OrderInput, OrderIntervalEnum, OrderStatusEnum } from "model"

type OrderFormProps = {
  order?: OrderInput,
  errors?: any
  onChange: (data: OrderInput) => void
}

export const OrderForm = (props: OrderFormProps) => {

  const contractListReponse = useQuery<{ contractList: Contract[] }>(contractListQuery, {
    fetchPolicy: "cache-and-network",
  })

  const contractList = contractListReponse?.data?.contractList || [];
  const paymentPrefList = contractList?.find((contract) => contract?.id === props?.order?.contractId)?.Customer?.PaymentPref

  const onChange = (d: OrderInput) => {
    props.onChange(d)
  }

  const errors = props?.errors
  const order = props?.order
  const { dateStart, dateEnd, status, interval, billingDayOfMonth, OrderLine, contractId } = order || {}

  return <div className="order-edit-container">
    <div className="input-group">
      <span className="input-group-text">Date</span>
      <div className="form-control" style={{ height: "100%" }}>
        <DateInput id="dateStart" errors={errors} label={"Date Début"} value={Number(dateStart) * 1000 || 0} onChange={v => onChange({
          ...order,
          dateStart: Math.round(v / 1000),
        })} />
        <DateInput id="dateEnd" errors={errors} label={"Date Fin"} value={Number(dateEnd) * 1000 || 0} onChange={v => onChange({
          ...order,
          dateEnd: Math.round(v / 1000),
        })} />
        <NumberInput id='billingDayOfMonth' value={`${billingDayOfMonth}`} label="Jour de Facturation" errors={errors} onChange={(e, v) => {
          onChange({
            ...order,
            billingDayOfMonth: v,
          })
        }} />
      </div>
    </div>
    <SelectInput id="status" label="Status" choiceList={[{ id: OrderStatusEnum.STATUS_DONE, label: "Terminé" }, { id: OrderStatusEnum.STATUS_ACTIVE, label: "Actif" }, { id: OrderStatusEnum.STATUS_DRAFT, label: "Brouillon" }, { id: OrderStatusEnum.STATUS_WAITING_VALIDATION, label: "En attente validation" }]} value={status || ""} errors={errors} onChange={v => {
      onChange({
        ...order,
        status: v as OrderStatusEnum
      })
    }} />

    <SelectInput id="interval" label="Interval" choiceList={[{ id: OrderIntervalEnum.INTERVAL_MONTH, label: "Mensuel" }, { id: OrderIntervalEnum.INTERVAL_YEAR, label: "Annnuel" }, { id: OrderIntervalEnum.INVERVAL_ONE_SHOT, label: "Unitaire" }]} value={interval || ""} errors={errors} onChange={v => {
      onChange({
        ...order,
        interval: v as OrderIntervalEnum
      })
    }} />
    <div className="input-group">
      <div className="input-group-text">Contrat</div>
      <select name="Contract" className="form-control" value={contractId || ""} onChange={(e) => {
        onChange({
          ...order,
          contractId: e.target.value,
        })
      }}>
        <option value="" disabled={true}>Choisissez une valeur</option>
        {contractList && [...contractList]
          ?.sort((el1: Contract, el2: Contract) => el1?.Customer?.name.localeCompare(el2?.Customer?.name))
          ?.map((contract: Contract) => {
            const { id, Customer } = contract
            const { name } = Customer || {}
            return <option key={`contract_${id}`} value={id}>{name}</option>
          })}
      </select>
      {errors?.contractId && <div className="input-group-text">
        <IconWarningSign />
      </div>}
    </div>
    <SelectInput isDefaultActive={true} defaultLabel="Aucune" id="paymentPrefId" value={order?.paymentPrefId || ""} errors={errors} label="Pref Paiement" choiceList={paymentPrefList?.map((ppref) => ({ id: ppref?.id || "", label: ppref?.BankAccount?.bankName || ppref?.Card?.last4 || "" })) || []} onChange={(v) => {
      onChange({
        ...order,
        paymentPrefId: v
      })
    }} />
    <div className="input-group">
      <div className="input-group-text">Lignes</div>
      <div className="child-container form-control" style={{ height: "100%" }}>
        <div className="child-container">
          {OrderLine && OrderLine.map((OrderLineItem: any, index: number) => {
            const { id } = OrderLineItem
            return <div key={`orderLine_${id}`} className="child-item">
              <div className="input-group">
                <span className="input-group-text bg-secondary"></span>
                <div className="form-control" style={{ height: "100%" }}>
                  <OrderLineForm
                    errors={errors?.OrderLine && errors?.OrderLine[index]}
                    orderLine={OrderLineItem}
                    onChange={(line: any) => onChange({
                      ...order,
                      OrderLine: order?.OrderLine?.map((l: any, idx: number) => (idx === index) ? line : l)
                    })}
                  />
                </div>
                <div className="input-group-text" onClick={() => onChange({
                  ...order,
                  OrderLine: order?.OrderLine?.filter((l: any, idx: number) => idx !== index)
                })}><span className="icon-trash"></span></div>
              </div>
            </div>
          })}
          <button className="btn btn-sm btn-dark" type="button" onClick={() => {
            onChange({
              ...order,
              OrderLine: [
                ...(order?.OrderLine ? order?.OrderLine : []),
                {
                  quantity: 1,
                  dateStart: null,
                  dateEnd: null,
                  itemId: null,
                },
              ]
            })
          }}>Ajouter</button>
        </div>
      </div>
      {errors?.OrderLine && <div className="input-group-text">
        <IconWarningSign />
      </div>}
    </div>
  </div>


}