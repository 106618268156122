import { useQuery } from "@apollo/client"
import { IconTrash } from "@zipou/front_tools"
import tagListGql from "graphql/BILLING/Tag/query/tagList.gql"
import { Tag } from "model"

type TagSelectInputProps = {
  tagId: string,
  onChange: (tagId: string | null) => void,
}

export const TagSelectInput = ({ tagId, onChange }: TagSelectInputProps) => {

  const { data } = useQuery<{ list: Tag[] }>(tagListGql)
  const tagList = data?.list

  return <div className="input-group">
    <span className="input-group-text">Tag</span>
    <select className="form-control" value={tagId || ""} onChange={(e) => onChange(e.target.value)}>
      <option value="" disabled>Choisissez</option>
      {tagList?.map(tag => {
        return <option value={`${tag?.id}`} key={`tag_${tag?.id}`}>{tag?.name}</option>
      })}
    </select>
    {!!tagId && <span className="input-group-text" onClick={() => onChange(null)}>
      <IconTrash />
    </span>}
  </div >

}

